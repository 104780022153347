import axios from 'axios'
import showToastify from '../utils/toastify'
import 'toastify-js/src/toastify.css'
import { find } from 'utils/dom'
import IMask from 'imask'

export default function trabalheConosco() {
	const contato = find("[js-page='contato']")
	if (!contato) return

	const element = document.querySelector('#form-trabalho input[name=telefone]')
	const maskOptions = {
		mask: [
			{
				mask: '(00) 0000-0000',
			},
			{
				mask: '(00) 00000-0000',
			},
		],
	}
	const mask = IMask(element, maskOptions)

	const contatoFormSubmit = document.querySelector('#form-trabalho')
	contatoFormSubmit &&
		contatoFormSubmit.addEventListener('submit', function (event) {
			event.preventDefault()
			disabledBtn()

			const formData = new FormData()
			formData.append('nome', document.querySelector('#form-trabalho input[name=nome]').value)
			formData.append('email', document.querySelector('#form-trabalho input[name=email]').value)
			formData.append('telefone', document.querySelector('#form-trabalho input[name=telefone]').value)
			formData.append('mensagem', document.querySelector('#form-trabalho textarea[name=mensagem]').value)
			formData.append('assunto', document.querySelector('#form-trabalho input[name=area]').value)
			formData.append('arquivo', document.querySelector('#form-trabalho input[name=curriculo]').files[0])
			const url = '/trabalhe-conosco/'
			axios
				.post(url, formData)
				.then((response) => showMessage(response))
				.catch((response) => showMessage(response))
		})

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector('#form-trabalho button[type=submit]')

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add('disabled')
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove('disabled')
	}

	const fileImage = document.querySelector('input[type="file"]');
	const textLabel = document.querySelector(".select-file");
	fileImage.addEventListener("change", () => {
		textLabel.innerText = fileImage.value.slice(12);
	});
}
