export default function homeNegocios() {
	const inputsForm = document.querySelectorAll("input[type='radio']")
	const inputPersonalizados = document.querySelectorAll('.inputPersonalizado')
	const itensAtividadeRight = document.querySelectorAll('[data-input-atividade2]')

	inputsForm.forEach((i) => {
		i.addEventListener('change', (e) => {
			const item = i.dataset.inputAtividade
			itensAtividadeRight.forEach((i) => {
				i.classList.add('hidden')
				if (item === i.dataset.inputAtividade2) i.classList.toggle('hidden')
				else console.log('diferente')
			})
		})
	})

	inputPersonalizados.forEach((i) => {
		i.addEventListener('change', () => {
			inputPersonalizados.forEach((item) => {
				item.classList.remove('marginBottomBlue')
			})
			i.classList.add('marginBottomBlue')
		})
	})
}
