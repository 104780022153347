export default function changeItemHistoria() {
	const itens = document.querySelectorAll('[data-item-history]')

	itens.forEach((i) => {
		i.addEventListener('click', () => {
			itens.forEach((i) => {
				const itemActive = i.querySelector('a')
				const itemDecorator = i.querySelector('div')
				itemActive.classList.add('text-linhares-primary-60')
				itemActive.classList.remove('text-linhares-primary-80')
				itemDecorator.classList.add('hidden')
			})

			const itemActive = i.querySelector('a')
			const itemDecorator = i.querySelector('div')
			itemActive.classList.remove('text-linhares-primary-80')
			itemActive.classList.add('text-linhares-primary-60')
			itemDecorator.classList.remove('hidden')
			itemDecorator.classList.add('bottom-0')
		})
	})
}
