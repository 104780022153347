import Swiper from "swiper";

const TimeLine = () => {
    const SwiperTimeLine = new Swiper('.swiper-time-line', {
        slidesPerView: 1,
        navigation: {
            nextEl: ".swiper-button-next-timeline",
            prevEl: ".swiper-button-prev-timeline",
        },
        breakpoints: {
            767: {
                slidesPerView: "auto",
            }
        }
    })
}

export default TimeLine