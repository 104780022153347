import axios from 'axios'
import showToastify from '../utils/toastify'
import 'toastify-js/src/toastify.css'
import { find } from 'utils/dom'
import IMask from 'imask'

export default function duvidasSugestoes() {
	const contato = find("[js-page='contato']")
	if (!contato) return

	const element = document.querySelector('#form-duvidas input[name=telefone]')
	const maskOptions = {
		mask: [
			{
				mask: '(00) 0000-0000',
			},
			{
				mask: '(00) 00000-0000',
			},
		],
	}
	const mask = IMask(element, maskOptions)

	const contatoFormSubmit = document.querySelector('#form-duvidas')
	contatoFormSubmit &&
		contatoFormSubmit.addEventListener('submit', function (event) {
			event.preventDefault()
			disabledBtn()

			const formData = new FormData()
			formData.append('nome', document.querySelector('#form-duvidas input[name=nome]').value)
			formData.append('email', document.querySelector('#form-duvidas input[name=email]').value)
			formData.append('telefone', document.querySelector('#form-duvidas input[name=telefone]').value)
			formData.append('mensagem', document.querySelector('#form-duvidas textarea[name=mensagem]').value)
			formData.append('assunto', document.querySelector('#form-duvidas select[name=assunto]').value)

			const url = '/duvidas-sugestoes/'
			axios
				.post(url, formData)
				.then((response) => showMessage(response))
				.catch((response) => showMessage(response))
		})

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector('#form-duvidas button[type=submit]')

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add('disabled')
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove('disabled')
	}
}
