import Toastify from 'toastify-js'

const showToastify = (message, opts) => {
	Toastify({
		duration: 3000,
		position: 'center',
		style: { background: 'rgba(27, 44, 97, 1)', color: 'rgba(255,255,255,1)' },
		text: message,
		...opts,
	}).showToast()
}

export default showToastify
